@import url("../src/styles/headings.css");
@import url("../src/styles/navbar.css");
@import url("../src/styles/hero-section.css");
@import url("../src/styles/experience-section.css");
@import url("../src/styles/skills-section.css");
@import url("../src/styles/portfolio-section.css");
@import url("../src/styles/contact-section.css");
@import url("../src/styles/footer.css");

/* Global CSS */
html,
body,
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: "Kollektif", sans-serif;
  -webkit-font-smoothing: "Kollektif", sans-serif;
  -moz-osx-font-smoothing: "Kollektif", sans-serif;
  font-style: normal;
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
:root {
  --primary: #5672dd;
  --heading-color: #282938;
  --bg-shade: #f5fcff;
  --github: #5672dd;
  --darkblue: #1c1e53;
  --black: #000000;
  --white: #fffcf4;
  --lime: #c1ff72;
  --backing-blue: #244db6;
}
.btn {
  font-family: "Kollektif", sans-serif;
  display: inline-block;
  padding: 14px 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
}
.btn-outline-primary {
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--backing-blue);
  box-shadow: 2px 2px var(--backing-blue);
}
.btn-outline-primary:hover {
  color: var(--white);
  background-color: var(--backing-blue);
  box-shadow: 1px 1px var(--backing-blue);
  transform: translateY(1px);
}
.btn-outline-primary:active {
  color: var(--white);
  background-color: var(--backing-blue);
  box-shadow: 0px 0px var(--backing-blue);
  transform: translateY(1px);
}
.btn-primary {
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
}
.btn-primary:hover {
  color: var(--primary);
  background-color: var(--backing-blue);
}
.btn-github {
  color: var(--primary);
  background-color: var(--lime);
  border: var(--github);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1.333px solid var(--primary);
  box-shadow: 10px 10px var(--backing-blue);
  gap: 16px;
}
.github--logo {
  width: 20%;
}
.btn-github:hover .github--logo {
  content: url("/public/img/github-mark.svg");
}
.btn-github:hover {
  color: var(--lime);
  background-color: var(--primary);
  border-color: var(--github);
  box-shadow: 5px 5px var(--backing-blue);
  transform: translateY(3px);
  border-width: 1px;
}
.btn-github:active {
  color: var(--lime);
  background-color: var(--primary);
  border-color: var(--github);
  box-shadow: 0px 0px var(--backing-blue);
  transform: translateY(5px);
  border-width: 1px;
}
.github--link {
  text-decoration: none;
  color: var(--primary);
}
@media only screen and (max-width: 600px) {
  .btn-outline-primary {
    display: none;
  }
}
/* Global CSS Ends */

/* Tablet Screens */
@media only screen and (min-width: 768px) and (max-width: 1023px) { 
  .btn-github {
    color: var(--primary);
    background-color: var(--lime);
    border: var(--github);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1.333px solid var(--primary);
    box-shadow: 8px 8px var(--backing-blue);
    gap: 16px;
  }
  .btn-github:hover {
    color: var(--primary);
    background-color: var(--lime);
    border-color: var(--github);
    box-shadow: 8px 8px var(--backing-blue);
    transform: none;
    border-width: 1px;
  }
  .btn-github:active {
    color: var(--lime);
    background-color: var(--lime);
    border-color: var(--github);
    box-shadow: 8px 8px var(--backing-blue);
    transform: none;
    border-width: 1px;
  }
  .btn-github:hover .github--logo {
    content: url("/public/img/github-mark-white.svg");
  }
}



@media only screen and (max-width: 767px) {
  .btn {
    -webkit-transition: background-color none;
    -moz-transition: background-color none;
    -ms-transition: background-color none;
    -o-transition: background-color none;
    transition: background-color none;
  }

  .btn-github {
    color: var(--primary);
    background-color: var(--lime);
    border: var(--github);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1.333px solid var(--primary);
    box-shadow: 5px 5px var(--backing-blue);
    gap: 16px;
  }
  .btn-github:hover {
    color: var(--primary);
    background-color: var(--lime);
    border-color: var(--github);
    box-shadow: 5px 5px var(--backing-blue);
    transform: none;
    border-width: 1px;
  }
  .btn-github:active {
    color: var(--lime);
    background-color: var(--lime);
    border-color: var(--github);
    box-shadow: 5px 5px var(--backing-blue);
    transform: none;
    border-width: 1px;
  }
  .btn-github:hover .github--logo {
    content: url("/public/img/github-mark-white.svg");
  }
}

/* Mobile screens */
@media only screen and (max-width: 767px) {
  .btn {
    -webkit-transition: background-color none;
    -moz-transition: background-color none;
    -ms-transition: background-color none;
    -o-transition: background-color none;
    transition: background-color none;
  }

  .btn-github {
    color: var(--primary);
    background-color: var(--lime);
    border: var(--github);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1.333px solid var(--primary);
    box-shadow: 5px 5px var(--backing-blue);
    gap: 16px;
  }
  .btn-github:hover {
    color: var(--primary);
    background-color: var(--lime);
    border-color: var(--github);
    box-shadow: 5px 5px var(--backing-blue);
    transform: none;
    border-width: 1px;
  }
  .btn-github:active {
    color: var(--lime);
    background-color: var(--lime);
    border-color: var(--github);
    box-shadow: 5px 5px var(--backing-blue);
    transform: none;
    border-width: 1px;
  }
  .btn-github:hover .github--logo {
    content: url("/public/img/github-mark-white.svg");
  }
  .btn-outline-primary {
    color: var(--white);
    background-color: var(--primary);
    border: 1px solid var(--backing-blue);
    box-shadow: 2px 2px var(--backing-blue);
  }
  .btn-outline-primary:hover {
    color: var(--white);
    background-color: var(--primary);
    border: 1px solid var(--backing-blue);
    box-shadow: 2px 2px var(--backing-blue);
    transform: none;
  }
  .btn-outline-primary:active {
    color: var(--white);
    background-color: var(--primary);
    border: 1px solid var(--backing-blue);
    box-shadow: none;
  }
}

@media only screen and (max-height: 767px) and (orientation: landscape) {
  .btn {
    -webkit-transition: background-color none;
    -moz-transition: background-color none;
    -ms-transition: background-color none;
    -o-transition: background-color none;
    transition: background-color none;
  }

  .btn-github {
    color: var(--primary);
    background-color: var(--lime);
    border: var(--github);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1.333px solid var(--primary);
    box-shadow: 5px 5px var(--backing-blue);
    gap: 16px;
  }
  .btn-github:hover {
    color: var(--primary);
    background-color: var(--lime);
    border-color: var(--github);
    box-shadow: 5px 5px var(--backing-blue);
    transform: none;
    border-width: 1px;
  }
  .btn-github:active {
    color: var(--lime);
    background-color: var(--lime);
    border-color: var(--github);
    box-shadow: 5px 5px var(--backing-blue);
    transform: none;
    border-width: 1px;
  }
  .btn-github:hover .github--logo {
    content: url("/public/img/github-mark-white.svg");
  }
  .btn-outline-primary {
    color: var(--white);
    background-color: var(--primary);
    border: 1px solid var(--backing-blue);
    box-shadow: 2px 2px var(--backing-blue);
  }
  .btn-outline-primary:hover {
    color: var(--white);
    background-color: var(--primary);
    border: 1px solid var(--backing-blue);
    box-shadow: 2px 2px var(--backing-blue);
    transform: none;
  }
  .btn-outline-primary:active {
    color: var(--white);
    background-color: var(--primary);
    border: 1px solid var(--backing-blue);
    box-shadow: none;
  }
}



