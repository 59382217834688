.footer--container {
  display: flex;
  padding: 32.667px 85.333px;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--primary);
  align-self: stretch;
}
.footer--link--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
}
.footer--items ul > li > a {
  color: var(--white);
  text-decoration: none;
  cursor: pointer;
}
.footer--social--icon > ul {
  color: var(--white);
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  text-decoration: none;
}
.divider {
  margin: 32px 0 42.67px;
  height: 1.333px;
  width: 100%;
  background: var(--white);
}
.footer--content--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--content {
  color: var(--white);
  font-size: 18.667px;
  font-weight: 400;
  line-height: 28px;
}

/* Tablet Screens */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .footer--link--container,
  .footer--items > ul,
  .footer--content--container {
    display: flex;
    flex-direction: column;
  }
  .footer--container {
    gap: 20px;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .footer--social--icon > ul,
  .footer--items > ul {
    padding: 0;
    align-items: center;
  }
  .divider {
    margin: 10px;
  }
}
/* Mobile screens */
@media only screen and (max-width: 767px) {
    .footer--link--container,
  .footer--items > ul,
  .footer--content--container {
    display: flex;
    flex-direction: column;
  }
  .footer--container {
    gap: 16px;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .footer--social--icon > ul,
  .footer--items > ul {
    padding: 2px;
    align-items: center;
  }
  .footer--items > ul {
    gap: 20px;
  }

  .divider {
    margin: 10px;
  }
}
@media only screen and (max-height: 767px) and (orientation: landscape) {
  .footer--container {
    display: flex;
    padding: 32.667px 85.333px;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--primary);
    align-self: stretch;
  }
  .footer--link--container {
    display: flex;
    
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap: 10px;
  }
  .footer--items > ul {
    flex-direction: row;  /* Make links side by side */
    gap: 15px;  /* Reduced gap between links */
}
  
}
/* Smaller Mobile Screens */
@media only screen and (max-width: 415px) {
}
