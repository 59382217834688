/* Main Headings */

/* Heading 1 */
h1 {
  color: var(--lime);
  font-size: 56px;
  font-weight: 700;
  line-height: 67px;
}
/* Heading 2 */
h2 {
  color: var(--backing-blue);
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
}
/* Heading 3 */
h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

/* Paragraphs text large medium small */
/* Body 1 */
.text-lg {
  color: var(--backing-blue);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
/* Body 2 */
.text-md {
  color: var(--backing-blue);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
/* Body 3 */
.text-sm {
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}
/* Section Titles */
/* Skills & About Me */
.section--title {
  color: var(--backing-blue);
  font-size: 25px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  display: flex;
  align-items: flex-start;
}
/* Portfolio, Testimonial & Contact Me */
.sub--title {
  color: var(--backing-blue);
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
/* Section Titles Ends */

/* Tablet Screens */
@media only screen and (min-width: 768px) and (max-width: 1023px){
  .skills--section--heading,
  .sections--heading {
    font-size: 35px;
    line-height: 40px;
  }
  .hero--section--title {
    font-size: 35px;
    line-height: 40px;
  }
  .hero--section--title,
  .skills--section--heading,
  .section--heading {
    font-size: 30px;
    line-height: 30px;
    text-align: center;
  }
  
  
}

@media only screen and (max-height: 767px) and (orientation: landscape) {
  
}


/* Mobile screens */
@media only screen and (max-width: 767px){
}
/* Smaller Mobile Screens */
@media only screen and (max-width: 415px) {}