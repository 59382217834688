.hero--section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0px 85.333px 0px;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  height: 100vh;
  background-image: url("/public/img/v2-background-hero.svg");
  background-color: var(--white);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
}

.hero--section--content--box > div > .section--title {
  color: var(--white);
}

.hero--section--content--box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  background-color: var(--primary);
  border-radius: 10px;
  box-shadow: 20px 20px var(--backing-blue);
  padding: 20px;
  margin: 20px;
}

.hero--section--content--box
  > .hero--section--buttons--icons
  > .hero--section--buttons
  > button {
  background-color: var(--lime);
  box-shadow: 2px 2px var(--backing-blue);
}

.hero--section--buttons--icons {
  margin-top: 21.333px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.hero--section--buttons {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-start;
}
.hero--section--content--box
  > .hero--section--buttons--icons
  > .hero--section--buttons
  > button:hover {
  color: var(--white);
  background-color: var(--backing-blue);
  box-shadow: 1px 1px #244db6;
  transform: translateY(1px);
}

.hero--section--content--box
  > .hero--section--buttons--icons
  > .hero--section--buttons
  > button:active {
  color: var(--white);
  background: var(--backing-blue);
  box-shadow: 0px 0px #244db6;
  transform: translateY(2px);
}

.hero--section--title {
  font-size: 74.667px;
  font-weight: 500;
  line-height: 90px;
  align-self: stretch;
  color: var(--lime);
}
.hero--section--title--color {
  color: var(--white);
}
.hero--section-description {
  color: var(--white);
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
}
.hero--section--img {
  display: flex;
}

.hero--section--img > img {
  width: 80%;
  height: 80%;
  border-radius: 10px 10px;
  border: 15px solid var(--primary);
  box-shadow: 20px 20px var(--backing-blue);
}

.hero--social--icons {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

/* Tablet Screens */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hero--section {
    background-image: url("/public/img/v2-mobile-background-xl-tb.png");
    background-color: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    min-height: 100vh;
  }
  .hero--section--title {
    font-size: 50px;
    line-height: 60px;
  }
  .hero--section {
    display: flex;
    flex-direction: column;
    padding: 50px 0;
    padding-bottom: 0px;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }
  .hero--section--content--box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    background-color: var(--primary);
    border-radius: 10px;
    box-shadow: 10px 10px var(--backing-blue);
  }
  .hero .skills--section--heading {
    font-size: 35px;
    line-height: 40px;
  }
  .hero--section--img > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    box-shadow: 10px 10px var(--backing-blue);
  }
  .hero--section--content--box {
    max-width: 100%;
    gap: 0px;
  }

  .p-center,
  .btn-center {
    display: block;
    margin: 0 auto;
  }

  .hero--section--img {
    width: 60%;
    height: auto;
  }
  .hero--section--content--box {
    width: auto;
    height: auto;
  }
  .hero--section--title {
    font-size: 65px;
    line-height: 52px;
  }
  .hero--section--content--box
    > .hero--section--buttons--icons
    > .hero--section--buttons
    > button:hover {
    color: var(--primary);
    background-color: var(--lime);
    box-shadow: 2px 2px var(--backing-blue);
    transform: none;
  }

  .hero--section--content--box
    > .hero--section--buttons--icons
    > .hero--section--buttons
    > button:active {
    color: var(--primary);
    background: var(--lime);
    box-shadow: 2px 2px var(--backing-blue);
    transform: none;
  }
}

/* Mobile screens */
@media only screen and (max-width: 767px) {
  .hero--section {
    background-image: url("/public/img/v2-mobile-background-xl.png");
    background-color: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    min-height: 100vh;
  }
  .hero--section {
    display: flex;
    flex-direction: column;
    padding: 50px 0;
    padding-bottom: 0px;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .hero--section--content > .section--title,
  .hero--section--title,
  .hero--section-description,
  .footer--content {
    text-align: center;
  }
  .hero--section--content,
  .hero--section--content--box {
    align-items: center;
  }
  .hero--section--content--box {
    gap: 5px;
    box-shadow: 5px 5px var(--backing-blue);
    box-sizing: border-box;
  }

  .hero--section--img {
    width: 70%;
    height: auto;
  }

  .hero--section--img > img {
    height: auto;
    width: 100%;
    object-fit: cover;
    align-items: center;
    box-shadow: 5px 5px var(--backing-blue);
    border: 5px solid var(--primary);
  }
  .hero--section--content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .hero--section--buttons--icons {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 45px;
    justify-items: center;
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .p-center,
  .btn-center {
    display: block;
    margin: 0 auto;
  }
  .hero--section--title {
    font-size: 35px;
    line-height: 60px;
  }
  .hero--section--img {
    width: 50%;
    height: auto;
  }
  .hero--section--content--box {
    width: auto;
    height: auto;
  }
  .hero--section--title {
    font-size: 32px;
    line-height: 52px;
  }

  .hero--section--content--box
    > .hero--section--buttons--icons
    > .hero--section--buttons
    > button:hover {
    color: var(--primary);
    background-color: var(--lime);
    box-shadow: 2px 2px var(--backing-blue);
    transform: none;
  }

  .hero--section--content--box
    > .hero--section--buttons--icons
    > .hero--section--buttons
    > button:active {
    color: var(--primary);
    background: var(--lime);
    box-shadow: 2px 2px var(--backing-blue);
    transform: none;
  }
}
/*Mobile Landscape*/
@media only screen and (max-height: 767px) and (orientation: landscape) {
  .hero--section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    padding: 0px 30px; 
    padding-top: 50px;
    gap: 16px; 
    height: auto;
    min-height: 100vh;
  }

  .hero--section--content--box > div > .section--title {
    font-size: 24px; 
  }

  .hero--section--content--box {
    gap: 16px; 
    padding: 10px; 
    margin: 10px; 
    box-shadow: 5px 5px var(--backing-blue);
  }

  .hero--section--buttons--icons {
    margin-top: 10px; 
  }

  .hero--section--title {
    font-size: 40px; 
    line-height: 48px; 
  }

  .hero--section-description {
    font-size: 18px; 
    line-height: 26px; 
  }

  .hero--section--img {
    display: flex;
   justify-content: center;
   align-items: center;

   margin-left: auto;
   margin-right: auto;
  }

  .hero--section--img > img {
    width: 90%; 
    height: 90%; 
    border-width: 5px; 
    box-shadow: 5px 5px var(--backing-blue);
  }

  .hero--social--icons {
    gap: 10px; 
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .hero--section {
    background-image: url("/public/img/v2-mobile-background-xl-tb.png");
    background-color: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    min-height: 100vh;
  }
}
/* Smaller Mobile Screens */
@media only screen and (max-width: 415px) {
  .hero--section--img {
    width: 50%;
    height: auto;
  }
  .hero--section--content--box {
    width: auto;
    height: auto;
  }
  .hero--section--title {
    font-size: 30px;
    line-height: 50px;
  }
}
