.skills--section {
  display: flex;
  padding: 0px 85.33px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 106.667px;
  background-image: url("/public/img/v2-background-hero.svg");
  background-color: var(--white);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
}
.skills--section--heading {
  color: var(--backing-blue);
  font-size: 64px;
  font-weight: 700;
  line-height: 77px;
}
.skills--section--container {
  display: grid;
  justify-content: center;
  align-items: start; /* Changed from flex-start to start */
  gap: 42.6px;
  grid-template-columns: repeat(4, 1fr); /* This sets up four columns */
  width: 100%; /* Ensure the container is full width */
  grid-auto-rows: minmax(min-content, max-content);
 
}
.skills--section--card {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  border-radius: 10.6px;
  background-color: var(--primary);

  box-shadow: 20px 20px var(--backing-blue);
  min-height: 500px;
}
.skills--section--card:hover {
  box-shadow: 10px 10px var(--backing-blue);
  transform: translateY(4px);
  border-bottom: 4px solid var(--lime);
}
.skills--section--card:hover .skills--section--description {
  color: var(--lime);
}
.skills--section--img > img {
  width: 100%;
  height: 100%;
}
.skills--section--img {
  display: flex;
  padding: 13.3px;
  justify-content: center;
  align-items: center;
  gap: 13.3px;
  border-radius: 10.6px;
  background: none;

  width: 60%;
}
.skills--section--card--content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.skills--section--title {
  color: var(--white);
  font-size: 32px;
  font-weight: 700;
  line-height: 45px;
}
.skills--section--description {
  color: var(--white);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

/* Tablet Screens */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .skills--section {
    background-image: url("/public/img/v2-mobile-background-xl-tb.png");
    background-color: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    min-height: 100vh;
  }
  .skills--section {
    gap: 20px;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .skills--section--container {
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .skills--section--card {
    gap: 30px;
    box-shadow: 10px 10px var(--backing-blue);
    min-height: 300px;
  }
  .skills--section--card--content {
    gap: 25px;
  }
  .skills--section--title {
    font-size: 23px;
    line-height: 30px;
  }
  .skills--section--description {
    font-size: 17.333px;
    line-height: 27px;
  }

  .skills--section > .portfolio--container {
    align-items: center;
    width: 100%;
  }
  .skills--section--card:hover {
    transform: none;
    border-bottom: none;
    box-shadow: 10px 10px var(--backing-blue);
  }
  
}

/* Mobile screens */
@media only screen and (max-width: 767px) {
  .skills--section {
    background-image: url("/public/img/v2-mobile-background-xl.png");
    background-color: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    min-height: 100vh;
  }
  .skills--section {
    align-items: center;
    gap: 16px;
    padding: 10px 16px;
  }
  .skills--section--title {
    font-size: 24px;
    line-height: 25px;
  }
  .skills--section--heading {
    color: var(--backing-blue);
    font-size: 45px;
    font-weight: 700;
    line-height: 77px;
  }
  .skills--section--container {
    gap: 30px;
    width: 80%;
    display: flex;
    flex-direction: column;

  }
  .skills--section--card {
    box-shadow: 5px 5px var(--backing-blue);
    gap: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex; /* Add this rule to make the content card a flex container */
    align-items: center; /* Add this rule to center the content vertically */
    min-height: 250px;
  }
  .skills--section--card--content {
    gap: 20px;
  }
  .skills--section--description {
    font-size: 16px;
    line-height: 23px;
  }
  .skills--section > .portfolio--container {
    padding-top: 50px;
  }
  .skills--section--img > img {
    width: 100%;
    height: 100%;
  }
  .skills--section--img {
    display: flex;
    padding: 13.3px;
    justify-content: center;
    align-items: center;
    gap: 13.3px;
    border-radius: 10.6px;
    background: none;
  
    width: 40%;
  }
  .skills--section--card:hover {
    transform: none;
    border-bottom: none;
    box-shadow: 5px 5px var(--backing-blue);
  }
  
 
}
@media only screen and (max-height: 767px) and (orientation: landscape) {
  .skills--section--container {
    grid-template-columns: repeat(4, 1fr);
  }
  .skills--section {
    padding: 50px 30px;
  }
  .skills--section--card {
    box-shadow: 5px 5px var(--backing-blue);
    gap: 5px;
    padding-top: 5px;
    padding-bottom: 15px;
    display: flex; /* Add this rule to make the content card a flex container */
    align-items: center; /* Add this rule to center the content vertically */
    min-height: 250px;
  }
  .skills--section--card--content {
    gap: 5px;
  }
  .section--title {
    font-size: 20px;
  }
  .skills--section--heading {
    font-size: 30px;
  }
  .skills--section--title {
    color: var(--white);
    font-size: 23px;
  }
  .skills--section--description {
    
    font-size: 15px;
    line-height: 20px;
  }
  .skills--section > .portfolio--container {
    gap: 0px;
  }
  .skills--section--card:hover {
    transform: none;
    border-bottom: none;
    box-shadow: 5px 5px var(--backing-blue);
  }
  
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .skills--section {
    background-image: url("/public/img/v2-mobile-background-xl-tb.png");
    background-color: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    min-height: 100vh;
  }
}
/* Smaller Mobile Screens */
@media only screen and (max-width: 415px) {
  .skills--section {
    background-image: url("/public/img/v2-mobile-background-xl.png");
    background-color: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    min-height: 100vh;
  }
  .skills--section--heading {
    color: var(--backing-blue);
    font-size: 40px;
    font-weight: 700;
    line-height: 77px;
  }
}
