@font-face {
    font-family: 'Kollektif';
    src: url('../public/kollektif/Kollektif.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Kollektif';
    src: url('../public/kollektif/Kollektif-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Kollektif';
    src: url('../public/kollektif/Kollektif-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Kollektif';
    src: url('../public/kollektif/Kollektif-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }