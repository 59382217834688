.about--section {
  display: grid;
  padding: 0px 250px 0px 250px;
  align-items: center;
  background-image: url("/public/img/v2-background-hero.svg");
  background-color: var(--white);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
}
.about--section--content--box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  background-color: var(--primary);
  border-radius: 10px;
  box-shadow: 20px 20px var(--backing-blue);
  padding: 20px;
  margin: 20px;
}

.about--section-description {
  color: var(--white);
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  margin-bottom: 30px;
}


/* Tablet Screens */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .about--section--box {
    box-shadow: 10px 10px var(--backing-blue);
    width: 85%;
  }
  .about--section {
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-image: url("/public/img/v2-mobile-background-xl-tb.png");
    background-color: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    min-height: 50vh;
  }
  #AboutMe > div.hero--section--content--box.about--section--box > div > h1 {
    color: var(--lime);
    text-align: center;
    font-size: 70px;
  }

  .about--section--content--box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    background-color: var(--primary);
    border-radius: 10px;
    box-shadow: 10px 10px var(--backing-blue);
  }

  
  
}

@media only screen and (max-height: 480px) and (orientation: landscape){
  .about--section--box {
    box-shadow: 5px 5px var(--backing-blue);
    
  }
  #AboutMe > div > div > h1 {
    text-align: center;
    font-size: 30px;
  }
  #AboutMe > div > div > p {
    font-size: 15px;
    line-height: 20px;
  
  }
  
 
  .about--section--content--box {
    gap: 16px; 
    padding: 10px; 
    width: 85%;
    margin: 10px; 
    box-shadow: 5px 5px var(--backing-blue);
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .about--section {
    background-image: url("/public/img/v2-mobile-background-xl-tb.png");
    background-color: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    min-height: 100vh;
  }
}
/* Mobile screens */
@media only screen and (max-width: 767px) {
  .about--section {
    background-image: url("/public/img/v2-mobile-background-xl.png");
    background-color: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 0px;
    width: 100%;
    height: 100%;
  }

  #AboutMe > div > div > h1 {
    text-align: center;
    font-size: 30px;
  }
  #AboutMe > div > div > p {
    font-size: 15px;
    line-height: 20px;
  }
  
  .about--section--content--box {
    box-shadow: 5px 5px var(--backing-blue);
    width: 85%;
  }
 
}
@media only screen and (max-height: 767px) and (max-width: 767px) and (orientation: landscape){
  .about--section--box {
    box-shadow: 5px 5px var(--backing-blue);
    
  }
  #AboutMe > div > div > h1 {
    text-align: center;
    font-size: 30px;
  }
  #AboutMe > div > div > p {
    font-size: 15px;
    line-height: 20px;
  }
  
 
  .about--section--content--box {
    gap: 16px; 
    padding: 10px; 
    width: 85%;
    margin: 10px; 
    box-shadow: 5px 5px var(--backing-blue);
  }
}
/* Smaller Mobile Screens */
@media only screen and (max-width: 415px) {
  #AboutMe > div > div > h1{
    text-align: center;
    font-size: 30px;
  }

  .about--section-description {
    text-align: center;
    font-size: 22px;
    line-height: 30px;
  }
  .about--section--content--box {
    box-shadow: 5px 5px var(--backing-blue);
    width: 85%;
    padding: 50px 30px;
  }
}
