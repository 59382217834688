.contact--section {
  display: flex;
  padding: 50px 85px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 64px;
  background-image: url("/public/img/v2-background-hero.svg");
  background-color: var(--white);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
}

.contact--form--container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(5, auto);
  width: 40%;
  row-gap: 32px;
}
.container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 32px;
  row-gap: 32px;
  
}
.contact--label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10.6px;
  flex: 1 0 0;
  width: 100%;
}
.contact--input {
  display: flex;
  font-family: "Roboto", sans-serif;
  padding: 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1.333px solid var(--primary);
  border-width: 2px;
  background: var(--lime);
  box-shadow: 10px 10px var(--backing-blue);
  
}

.contact--label.email {
  grid-column: span 2;
}
input::placeholder,
textarea::placeholder {
  color: var(--backing-blue);
  font-family: "Kollektif", sans-serif;
}
.contact--label > input,
.contact--label > textarea {
  font-family: "Kollektif", sans-serif;
}
.contact--form--bt {
  color: var(--white);
  background: var(--primary);
  box-shadow: 8px 8px var(--backing-blue);
  border: 1px solid var(--primary);
}
.contact--form--bt:hover {
    color: var(--white);
    background: var(--primary);
    box-shadow: 3px 3px var(--backing-blue);
    transform: translateY(3px);
  }
.contact--form--bt:active {
  color: var(--white);
  background: var(--primary);
  box-shadow: 0px 0px var(--backing-blue);
  transform: translateY(2px);
}

/* Tablet Screens */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .contact--section {
    background-image: url("/public/img/v2-mobile-background-xl-tb.png");
    background-color: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    min-height: 100vh;
  }
  .contact--section {
    gap: 20px;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .contact--form--container {
    width: 100%;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .contact--form--bt {
    color: var(--white);
    background: var(--primary);
    box-shadow: 8px 8px var(--backing-blue);
    border: 1px solid var(--primary);
    transition: background-color none;
    -webkit-transition: background-color none;
    -moz-transition: background-color none;
    -ms-transition: background-color none;
    -o-transition: background-color none;
  }
  
  .contact--form--bt:hover {
    color: var(--white);
    background: var(--backing-blue);
    box-shadow: 8px 8px var(--backing-blue);
    transform: none;
  }
  .contact--form--bt:active {
    color: var(--white);
    background: var(--backing-blue);
    box-shadow: 8px 8px var(--backing-blue);
    transform: none;
  }

  .contact--input {
    -webkit-appearance: none;
    width: 100%;
    box-shadow: 8px 8px var(--backing-blue);
  }
  
}

/* Mobile screens */
@media only screen and (max-width: 767px) {
  .contact--section {
    background-image: url("/public/img/v2-mobile-background-xl.png");
    background-color: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    min-height: 100vh;
  }
  .contact--section {
    gap: 16px;
    padding: 100px 16px 10px 16px;
    align-items: center;
  }
  .contact--form--container {
    
    width: 80%;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .contact--input {
    -webkit-appearance: none;
    width: 100%;
    box-shadow: 5px 5px var(--backing-blue);
  }
  .contact--form--bt {
    box-shadow: 5px 5px var(--backing-blue);
  }
  .contact--form--bt:hover {
    color: var(--white);
    background: var(--backing-blue);
    box-shadow: 5px 5px var(--backing-blue);
    transform: none;
  }
  .contact--form--bt:active {
    color: var(--white);
    background: var(--backing-blue);
    box-shadow: 5px 5px var(--backing-blue);
    transform: none;
  }
  
}
@media only screen and (max-height: 767px) and (orientation: landscape){

  .contact--section > div > h2 {
    font-size: 30px;
  }
  .contact--input {
    -webkit-appearance: none;
    width: 100%;
    box-shadow: 5px 5px var(--backing-blue);
  }
  .contact--form--bt {
    box-shadow: 5px 5px var(--backing-blue);
  }
  .contact--form--bt:hover {
    color: var(--white);
    background: var(--backing-blue);
    box-shadow: 5px 5px var(--backing-blue);
    transform: none;
  }
  .contact--form--bt:active {
    color: var(--white);
    background: var(--backing-blue);
    box-shadow: 5px 5px var(--backing-blue);
    transform: none;
  }
  .contact--form--container {
    width: 70%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .contact--section {
    background-image: url("/public/img/v2-mobile-background-xl-tb.png");
    background-color: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    min-height: 100vh;
  }
}
/* Smaller Mobile Screens */
@media only screen and (max-width: 415px) {
  
}
