.portfolio--section {
  display: flex;
  padding: 100px 85px;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
  background-image: url("/public/img/v2-background-hero.svg");
  background-color: var(--white);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
}

.portfolio--section--title {
  color: var(--backing-blue);
}
.portfolio--container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.portfolio--container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 11.3px;
}
.portfolio--section--container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 42.6px;
  width: 90%;
}
.portfolio--section--img {
  background-color: var(--lime);
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
}
.portfolio--section--img > img {
  max-width: 100%;
  height: auto;
  border-radius: 12px;
}
.portfolio--section--card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  background: var(--lime);
  box-shadow: 20px 20px var(--backing-blue);
  cursor: pointer;
  border-radius: 12px;
  border: 2px solid var(--primary);
  max-height: auto;
  width: 70%;
}
.portfolio--section--card--content {
  display: flex;
  padding: 16px 20px 16px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  min-height: 250px;
}
.portfolio--link {
  text-decoration: none;
  display: flex;
  gap: 10px;
  border-bottom: 1.33px solid var(--primary);
  padding-bottom: 10.6px;
  font-weight: 600;
}
.portfolio--section--card:hover path {
  stroke: var(--white);
}

/* Tablet Screens */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .portfolio--section {
    background-image: url("/public/img/v2-mobile-background-xl-tb.png");
    background-color: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    min-height: 100vh;
    
  }
  .portfolio--container,
  .portfolio--container-box {
    gap: 30px;
  }
  .portfolio--container {
    align-items: center;
  }
  .portfolio--container-box {
    flex-direction: column-reverse;
  }
  .portfolio--section--container,
  .portfolio--container-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .portfolio--section--card {
    box-shadow: 10px 10px var(--backing-blue); 
    width: 100%;
    
  }
  .portfolio--section--container {
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
  }

  

}

/* Mobile screens */
@media only screen and (max-width: 767px) {
  .portfolio--section {
    background-image: url("/public/img/v2-mobile-background-xl.png");
    background-color: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    min-height: 100vh;
  }
  

  .portfolio--section--container,
  .portfolio--container-box {
    display: flex;
    flex-direction: column;
  }
  .portfolio--container {
    align-items: center;
  }
  .portfolio--container-box {
    gap: 20px;
    padding-top: 50px;
  }
  .portfolio--section {
    padding: 10px 16px;
    align-items: center;
  }

  .portfolio--section--card {
    box-shadow: 5px 5px var(--backing-blue);
    width: 88%;
  }
}
@media only screen and (max-height: 767px) and (orientation: landscape){
  .portfolio--section--container {
    gap: 25px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .portfolio--container-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .portfolio--section {
    padding: 20px 30px;
   
    align-items: center;
  }
  .portfolio--section--title {
    font-size: 20px;
  }
  .skills--section--card--content > .text-md {
    font-size: 15px;
  }
  .portfolio--section--card--content {
    padding: 8px 5px;
    gap: 4px;
  }
  .portfolio--section--card {
    box-shadow: 5px 5px var(--backing-blue);
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .portfolio--section {
    background-image: url("/public/img/v2-mobile-background-xl-tb.png");
    background-color: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    min-height: 100vh;
  }
}
/* Smaller Mobile Screens */
@media only screen and (max-width: 415px) {
}
